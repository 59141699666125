@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-[Montserrat] text-xs xl:text-sm 2xl:text-base;
}

#root {
  @apply flex flex-col sm:w-full xl:max-w-[1440px] 2xl:max-w-[1800px] items-center min-h-screen text-white;
}

body {
  @apply flex flex-col items-center text-lg bg-[#E9EEFF];
}

/* ------------------------------------------------------------------------- */

.firstSwiper .swiper-slide {
  @apply flex flex-row items-center justify-center text-center py-20 h-auto;
}

.firstSwiper {
  @apply w-2/3 m-0 mt-16 h-[30rem] rounded-2xl;
}

.swiper-pagination :nth-child(n) {
  @apply bg-white w-5 h-5;
}

.firstSwiper .simple-card {
  @apply flex flex-col justify-center w-1/2 space-y-8 z-10;
}

/* ------------------------------------------------------------------------- */
.simple-card {
  @apply flex flex-col justify-center w-9/12 space-y-8 z-10 pt-5;
}

.simple-card > h2 {
  @apply text-3xl font-bold;
}

/* ------------------------------------------------------------------------- */

.way {
  @apply flex flex-row justify-evenly w-2/3 mx-auto text-black;
}

.way> :nth-child(1) {
  @apply relative right-[6rem] 2xl:right-0 top-[7em]
}

.way> :nth-child(2) {
  @apply relative right-[3rem] 2xl:right-0
}

.way> :nth-child(3) {
  @apply relative top-[8rem] right-[1rem]
}

.way> :nth-child(4) {
  @apply relative 2xl:left-[1em] left-[4rem]
}

.way> :nth-child(5) {
  @apply relative top-[7em] 2xl:left-[1em] left-[6rem]
}

/* ------------------------------------------------------------------------- */

.child-centerd {
  @apply flex flex-col items-center;
}

/* ------------------------------------------------------------------------- */

.calculator {
  @apply w-2/3 mt-32 border-4 border-blue-800 rounded-3xl py-11 px-14 space-y-10 bg-no-repeat bg-cover bg-center bg-blue-900
}

.calculator .button {
  @apply rounded-full bg-white text-blue-900 text-xl font-bold text-center py-4 w-full h-fit cursor-pointer outline-none;
}

.calculator .button:not([data-active-plane]) {
  @apply border-blue-900;
}

[data-active-plane] {
  background-color: #A3B7F6 !important;
}

/* ------------------------------------------------------------------------- */

.robots-swiper .swiper-slide {
  @apply flex flex-col items-center justify-start text-center space-y-5 py-8 h-auto;
}

.robots-swiper .swiper-slide-prev,
.robots-swiper .swiper-slide-next,
.robots-swiper .swiper-slide {
  @apply bg-[#16338E80] rounded-3xl brightness-90 bg-cover bg-center bg-blend-overlay;
}

.robots-swiper .swiper-slide-active {
  @apply bg-[#16338E99] rounded-3xl brightness-100 bg-cover bg-center bg-blend-screen
}

/* ------------------------------------------------------------------------- */

.status-bar {
  @apply bg-white w-fit h-fit px-20 py-1 rounded-full text-black
}

.card-profile {
  @apply grid grid-cols-2 grid-rows-2 gap-1 w-2/3 text-center;
}

.card-profile> :nth-child(1n) {
  @apply flex flex-col items-center justify-end py-16 px-10 space-y-4 bg-blue-800;
}

.card-profile> :nth-child(4) {
  @apply justify-between
}

.card-profile> :nth-child(odd) img {
  @apply w-36
}

/* ------------------------------------------------------------------------- */

.characteristics {
  @apply grid grid-cols-3 grid-rows-3 mt-16;
}

.characteristics> :nth-child(1),
.characteristics> :nth-child(4),
.characteristics> :nth-child(7) {
  @apply justify-self-end
}

.characteristics> :nth-child(2),
.characteristics> :nth-child(5),
.characteristics> :nth-child(8) {
  @apply justify-self-center
}

.characteristics> :nth-child(3n + 3) {
  @apply justify-self-start
}

.characteristics > .card {
  @apply simple-card bg-blue-800 rounded-2xl pt-2 pb-10 px-5 text-center
}

.characteristics > .card > h2 {
  /* @apply mt-5 */
}

/* ----------------------------------------------------- */

.bg-statistics {
  @apply px-2 py-3 space-y-2 rounded-2xl self-end bg-no-repeat bg-cover bg-center bg-blue-300;
}

.bg-statistics > div {
  @apply bg-blue-900 bg-cover bg-center bg-no-repeat rounded-2xl px-5 py-1
}